import React, { Component } from "react";
import "./styles/ats.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { Spin } from "antd";
import ContentLoader from "./components/ContentLoader";
import Tacitbase from "./Tacitbase";
// import withVerificationChecks from "./OnboardingVerification";
import "react-phone-input-2/lib/style.css";



// Non-lazy loaded routes
import Onboarding from "./components/signUp/Onboarding";
import LoginPage from "./pages/signIn/LoginPage";
import FallBackErrorUI from "./components/pageNotFound/FallBackErrorUI";
import CreateOrgPage from "./pages/signUp/CreateOrgPage";
import InvitedSignUpPageLayout from "./pages/signUp/InvitedSignUpPageLayout"
import SignUpPageLayout from "./pages/signUp/SignUp";
import SubscriptionExpired from "./pages/signUp/SubscriptionExpired";
import ContactOwnerPage from "./pages/signUp/ContactOwnerPage";
import ReturnPage from "./pages/CalenderReturn";
import CommunicationIntegrationCodeHandler from "./pages/CommunicationIntegrationCodeHandler";
import PageNotFound from "./components/pageNotFound/PageNotFound";
import ForgetPassword from "./pages/login/ForgetPassword";
import ResetPwd from "./pages/resetPwd/ResetPwd";
import OrgCreationStatus from "./pages/signUp/OrgCreationStatus";
import { RedirectToNewCareerpage, RedirectToNewForm, RedirectTonewJD } from "./components/JdManagement/CareerPage/RedirectTonew";
import JobDescriptionForm from "./components/JdManagement/FormTemplate/JobDescriptionForm";
import Board from "./pages/board/Board";
import Pool from "./pages/pool/Pool";
import BoardJd from "./components/boardPanel/BoardJd";
import BoardForm from "./components/boardPanel/BoardForm";
import ActivityIndex from "./components/jobPost/tabs/activity/ActivityIndex";
import AnalyticsMainPage from "./pages/analytics/AnalyticsMainPage";
import SettingsPanel from "./components/jobPost/tabs/settings/SettingsPanel";
import ApplicantDetailView from "./components/jobPost/tabs/pool/ApplicantDetailView";
import Card from "./components/card/Card";
import BoardPanel from "./components/boardPanel/BoardPanel";



// lazily loaded routes

import Analytics from "./components/analyticsVersion1/AnalyticsMainPage";
import NotificationCenterIndex from "./components/notificationCenter/NotificationCenterIndex";
import SettingHeader from "./components/setting/SettingHeader";
import CandidateDBHeader from "./components/candidateDB/CandidateDBHeader";
import TmailHeader from "./components/Tmail/TmailHeader";
import ReviewIndex from "./components/Reviews/ReviewIndex";
import Homepage from "./components/pageContent/Homepage";
import ApplicationDetailedViewCDB from "./components/CDB/ApplicationDetailedViewCDB";
import DetailReview from "./components/Reviews/DetailReview";
import DetailedMailView from "./components/mail/DetailedMailView";
import DetailedSentMailView from "./components/mail/DetailedSentMailView";
import CalenderOAuthRedirect from "./pages/setting/accSetting/RedirectOauth";
import OrganizationPage from "./pages/signUp/OrganizationPage";
import OnboardingVerification from "./OnboardingVerification";
import EventFormModal from "./components/calender/global/CreateEvent";
import CalendarHeader from "./components/calender/global/CalenderHeader";
import BoardCalendarView from "./components/calender/board/BoardCalendarView";
import OAuthCallbackPage from "./styles/component/ESign/OAuthCallbackPage";





type atsProps = {
  pageAndContentLoader: { loader: boolean; message: string; skeleton: boolean };
};
class Ats extends Component<atsProps> {
  render() { 
    return (
      <Router>
        {this.props?.pageAndContentLoader?.loader && (
          <ContentLoader
            content={<></>}
            footer={<></>}
            message={
              this.props?.pageAndContentLoader?.message &&
              this.props.pageAndContentLoader.message
            }
            loaderIcon={
              <div className="flex flex-col space-y-6">
                {" "}
                <Spin tip="" size="large">
                  <div className="content" />
                </Spin>
              </div>
            }
          />
        )}
        <Tacitbase>

          <Switch>
          <Route exact  path="/oauth" component={CalenderOAuthRedirect} />

            {/* <ProtectedRoute 
              path="/register/verification/email/:verificationId?"
              component={withVerificationChecks(PleaseVerifyEmailPage)}
            /> */}
            {/* <ProtectedRoute exact
              path="/register/verification/phone"
              component={withVerificationChecks(VerifyPhone)}
            /> */}
            {/* <ProtectedRoute exact
              path="/register/setup/username"
              component={withVerificationChecks(Username)}
            /> */}
            <Route path="/register" component={OnboardingVerification(SignUpPageLayout)} />
            <Route
              path="/invite-register/:email"
              component={InvitedSignUpPageLayout}
            />  
            <Route path="/onboarding/:verificationId?" component={Onboarding} />      
            <ProtectedRoute
              path="/o/start-setup"
              component={OrgCreationStatus}
            />
            {/* <ProtectedRoute
              path="/o/workspace"
              component={WorkspaceCreation}
            /> */}
            {/* <ProtectedRoute path="/o/choose-plan" component={PlanPage} />  */}
            <ProtectedRoute
              path="/o/subscription-expired"
              component={SubscriptionExpired}
            />
            <ProtectedRoute
              path="/contact-admin"
              component={ContactOwnerPage}
            />
            <ProtectedRoute
              path="/o/:org_id/updates"
              component={NotificationCenterIndex}
            />
            <ProtectedRoute path="/create-org" component={CreateOrgPage} />
            <Route exact path={["/", "/login"]} component={OnboardingVerification(LoginPage)} />
            <Route
              exact
              path="/password/change/:changePasswordId"
              component={ResetPwd}
            />
            {/* old route of career page */}
            <Route path="/public/jd/:org_id/:jd_id" component={RedirectTonewJD} />
            <Route path="/public/jd/:org_id" component={RedirectToNewCareerpage} />

            <Route
              exact
              path="/public/form/:org_id/:form_id"
              component={RedirectToNewForm}
            />
           <ProtectedRoute 
              path="/o/:org_id/calendar"
              component={CalendarHeader}
            />
            <ProtectedRoute
              // exact
              path="/o/:org_id/db"
              component={CandidateDBHeader}
            />
            
            <ProtectedRoute
              exact
              path="/o/:org_id/analytics"
              component={Analytics}
            />

            <ProtectedRoute
                // exact
              path="/o/:org_id/tmail/:tabname"
              component={TmailHeader}
            />
            <Route exact path="/password/change" component={ForgetPassword} />
            {/* <Route path="/registration/verified" component={Verification} /> */}
            <ProtectedRoute exact path="/o" component={(OrganizationPage)} />
            <ProtectedRoute
              exact
              path="/o/:org_id/ats"
              identifire="org_id"
              component={Homepage}
            />
            <ProtectedRoute
              path="/o/:org_id/reviews"
              component={ReviewIndex}
              key=":review_id"
              identifire="review_id"
            />
            <ProtectedRoute
              exact
              path="/o/:org_id/settings/:tabname"
              component={SettingHeader}
            />
            {/* <ProtectedRoute exact path="/createOrg" component={CreateOrg} /> */}
            <Route
              path="/slackCode"
              component={CommunicationIntegrationCodeHandler}
            />
            <Route path="/code" component={ReturnPage} />
            {/* <ProtectedRoute
              exact
              path="/o/:org_id/members"
              component={Members}
            /> */}
            <Route path="/error" component={FallBackErrorUI} />

            <Route
              path="/public/jdform/:form_id"
              component={JobDescriptionForm}
            />{" "}
             <ProtectedRoute
              path="/o/:org_id/b/:board_id/:tabname"
              component={Board}
            />

            <ProtectedRoute
              path="/o/:org_id/b/:board_id/tracker"
              component={BoardPanel}
            />
            
            <ProtectedRoute
              path="/o/:org_id/b/:board_id/pool"
              component={Pool}
              />
               <ProtectedRoute
              path="/o/:org_id/b/:board_id/calendar"
              component={BoardCalendarView}
              />
               <ProtectedRoute
              path="/o/:org_id/b/:board_id/job-description"
              component={BoardJd}
              />
                <ProtectedRoute
              path="/o/:org_id/b/:board_id/form"
              component={BoardForm}
              />
                <ProtectedRoute
              path="/o/:org_id/b/:board_id/activity"
              component={ActivityIndex}
              />
                <ProtectedRoute
              path="/o/:org_id/b/:board_id/analytics"
              component={AnalyticsMainPage}
              />
                <ProtectedRoute
              path="/o/:org_id/b/:board_id/settings"
              component={SettingsPanel}
            />
             
             <Route path="/oauth/e-sign" component={OAuthCallbackPage} />
            <Route path="*" component={PageNotFound} />
          </Switch>
          <ProtectedRoute
          // exact
            path="/o/:org_id/tmail/inbox/:candidate_id"
            component={DetailedMailView}
            // key=":candidate_id"
            // identifire="candidate_id"
          />
          <ProtectedRoute
          // exact
            path="/o/:org_id/tmail/sent/:candidate_id"
            component={DetailedSentMailView}
            // key=":candidate_id"
            // identifire="candidate_id"
          />
          <ProtectedRoute
            path="/o/:org_id/b/:board_id/tracker/:card_id/"
            component={Card}
          />
          <ProtectedRoute
            path="/o/:org_id/b/:board_id/tracker/:card_id/:calendar_id/:event_id"
            component={EventFormModal}
          />
           <ProtectedRoute
            path="/o/:org_id/b/:board_id/calendar/:calendar_id/:event_id"
            component={EventFormModal}
          />
           
          <ProtectedRoute
            path="/o/:org_id/b/:board_id/pool/:candidate_id"
            component={ApplicantDetailView}
          />
          <ProtectedRoute 
            path="/o/:org_id/calendar/:calendar_id/:event_id"
            component={EventFormModal}
            />
          <ProtectedRoute
            path="/o/:org_id/db/:candidate_id"
            component={ApplicationDetailedViewCDB}
          />
          <ProtectedRoute
            path="/o/:org_id/reviews/:review_id/"
            component={DetailReview}
          />
          <ToastContainer autoClose={2000} transition={Slide} />
          {/* <Route render={()=>(
          <p>Not found</p>
        )}> */}
          {/* <Redirect to="/404" /> */}
        </Tacitbase>
      </Router>
    );
  }
}

const mapStateToProps = (state: any) => ({
  pageAndContentLoader: state.opening?.pageAndContentLoader,
});

export default connect(mapStateToProps)(Ats);
